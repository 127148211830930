let isInitiated = false;
const domains = ["*.bonnierdigitalservices.se", "*.expressen.se", "*.exputv.se", "*.internal.ohoy.io"];
export default function iframeSync() {
  if (isInitiated) return;
  isInitiated = true;
  let resizeTimeout;
  function extractDomain(url) {
    if (typeof url === "undefined" || !url) {
      return null;
    }
    const domainMatch = url.match(/\/\/([^/]*)/);
    return domainMatch && domainMatch[1];
  }
  function extractProtocol(url) {
    if (!url) return;
    const protocolMatch = url.match(/^https?/);
    return protocolMatch ? protocolMatch[0] : window.location.protocol.slice(0, -1);
  }
  function isWhitelistedDomain(url) {
    const domain = extractDomain(url);
    if (!domain) {
      return false;
    }
    const filteredDomains = domains.filter(d => {
      if (d.indexOf("*") === -1) {
        return d === domain;
      }
      return new RegExp(`^${d.replace(/\./g, "\\.").replace("*", ".*")}$`).test(domain);
    });
    return filteredDomains.length > 0;
  }
  function findIframe(event) {
    const iframes = document.getElementsByTagName("iframe");
    for (let i = 0; i < iframes.length; i++) {
      const frame = iframes[i];
      if (frame.contentWindow === event.source) {
        return frame;
      }
    }
    return false;
  }
  function receiveMessage(event) {
    let iframe;
    if (isWhitelistedDomain(event.origin) && typeof event.data === "string") {
      const heightMatch = event.data.match(/^_iframe_height_(.+)_(\d+)$/);
      if (heightMatch && heightMatch[1] && heightMatch[2]) {
        iframe = findIframe(event) || document.getElementById(heightMatch[1]);
        const height = Number(heightMatch[2]);
        if (!iframe || !height) return;
        iframe.style.height = `${height}px`;
      }
      const scrollMatch = event.data.match(/^_iframe_scroll_(.+)$/);
      if (scrollMatch && scrollMatch[1]) {
        iframe = findIframe(event) || document.getElementById(scrollMatch[1]);
        if (iframe) {
          const top = iframe.offsetTop + iframe.scrollTop;
          window.scrollTo(0, top);
        }
      }
    } else if (event.data.location && event.data.location.startsWith("https://ext.dn.se")) {
      if (!event.data.ratio) return;
      iframe = findIframe(event);
      if (!iframe) return;
      iframe.style.height = `${Math.round(event.data.ratio * iframe.offsetWidth)}px`;
    }
  }
  function resizeHandler() {
    clearInterval(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      const iframes = document.getElementsByTagName("iframe");
      for (let i = 0; i < iframes.length; i++) {
        const frame = iframes[i];
        const url = frame.getAttribute("src");
        if (isWhitelistedDomain(url)) {
          frame.contentWindow.postMessage("_iframe_resize", `${extractProtocol(url)}://${extractDomain(url)}`);
        }
      }
    }, 500);
  }
  window.addEventListener("message", receiveMessage, false);
  window.addEventListener("resize", resizeHandler, false);
}