import { checkConsentForPurpose } from "../integration/didomi";
import scroll from "@exp/scripts/helpers/scroll";
import slider from "@exp/components/slider/slider.mjs";
import { pushFurtherReadingEvent } from "@exp/scripts/integration/gtm";
import lazyLoadImages from "./lazy-load-images";
export default async function readMoreSlider(context) {
  const container = context && context.getElementsByClassName("read-more-slider")[0];
  if (!container) return;
  let abTest = false,
    isFallback = false;
  if (!container.classList.contains("latest-storytelling-articles-slider")) {
    abTest = await loadAbTestContent(container);
    isFallback = container.getAttribute("data-fallback") === "true";
  }
  scroll.add(impressionTracking);
  initSlider(container);
  initSliderClickTracking(container, abTest, isFallback);
  function impressionTracking() {
    if (scroll.isContainerInView(container)) {
      scroll.remove(impressionTracking);
      pushFurtherReadingEvent({
        abTest,
        isFallback
      }, "impression");
    }
  }
}
async function loadAbTestContent(container) {
  if (!window.Exp?.experiments?.matchy) return false;
  const hasConsent = await new Promise(resolve => checkConsentForPurpose("marketing", resolve));
  if (!hasConsent) return false;
  const markup = await getMatchyRecommendations();
  if (!markup) return false;
  const matchyElement = document.createElement("div");
  matchyElement.innerHTML = markup;
  if (matchyElement.firstChild) {
    if (matchyElement.firstChild.hasAttribute("data-fallback")) {
      container.setAttribute("data-fallback", "true");
    }
    container.innerHTML = matchyElement.firstChild.innerHTML;
    container.setAttribute("data-matchy", "true");
    lazyLoadImages(container);
  }
  return true;
}
export function initSlider(container) {
  slider(container, {
    itemContainer: "slider__items",
    controls: "slider__control",
    trackingLabel: "read-more-slider",
    alignLastItemToRight: true
  });
}
export function initSliderClickTracking(container, abTest, isFallback) {
  const links = container.querySelectorAll(".slider__item > a");
  if (!links.length) return;
  [...links].forEach((link, i) => {
    link.addEventListener("click", () => {
      if (link.href) {
        const titleElement = link.querySelector("h4");
        const title = titleElement ? titleElement.innerText.trim() : "";
        pushFurtherReadingEvent({
          title,
          url: link.href,
          index: i + 1,
          abTest,
          isFallback
        }, "read more slider");
      }
    });
  });
}
function getMatchyRecommendations() {
  const url = new URL("/read-more-recommendations", window.location.origin);
  url.searchParams.append("pathname", window.location.pathname);
  return fetch(url.toString(), {
    credentials: "same-origin"
  }).then(response => {
    if (response && response.status === 200) {
      return response.text();
    }
    return null;
  }).catch(() => {
    return null;
  });
}