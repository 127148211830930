import lazyLoadImages from "../plugins/lazy-load-images";
export default function init(componentInitiators) {
  componentInitiators.high.forEach(fn => fn());
  componentInitiators.medium.forEach(fn => fn());
  listPage();
}
function listPage() {
  const element = document.getElementsByClassName("page")[0];
  const loadMoreButton = element && element.getElementsByClassName("list-page__load-more")[0];
  const list = loadMoreButton && element.getElementsByClassName("list-page__items")[0];
  if (!list || !loadMoreButton) return;
  let nextPage = 2;
  const {
    type,
    ...nextOptions
  } = loadMoreButton.dataset;
  const nextParams = new URLSearchParams(nextOptions);
  let lazyImages;
  loadMoreButton.addEventListener("click", loadMore);
  function loadMore() {
    if (type === "todays-news") {
      const date = list.lastElementChild.getElementsByTagName("time")[0].getAttribute("datetime");
      nextParams.set("date", date);
    } else {
      nextParams.set("page", nextPage);
    }
    loadMoreButton.disabled = true;
    lazyImages = lazyImages || list.getElementsByClassName("lazy");
    const newLazyImagesStart = lazyImages.length;
    const path = `/list-page-next/${type}/?${nextParams.toString()}`;
    fetch(path, {
      credentials: "same-origin"
    }).then(result => {
      if (!result.ok) return;
      if (result.headers.has("last-page")) {
        loadMoreButton.classList.add("hidden");
      }
      return result.text();
    }).then(markup => {
      loadMoreButton.disabled = false;
      if (!markup) return;
      list.insertAdjacentHTML("beforeend", markup);
      nextPage++;
    }).then(() => {
      const newLazyImages = batchLazyImages(newLazyImagesStart, lazyImages.length);
      if (!newLazyImages) return;
      lazyLoadImages(null, newLazyImages);
    }).catch(() => {
      loadMoreButton.disabled = false;
    });
  }
  function batchLazyImages(start, end) {
    if (end === start) return;
    const batch = [];
    for (let i = start; i < end; ++i) {
      batch.push(lazyImages[i]);
    }
    return batch;
  }
}