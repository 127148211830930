import slider from "@exp/components/slider/slider.mjs";
import bullets from "@exp/components/slider/bullets.mjs";
import { manuallyLoadImage } from "@exp/scripts/plugins/lazy-load-images";
export default function setup(params) {
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
  const containers = context.getElementsByClassName(params.container);
  if (!containers) return;
  for (let i = 0; i < containers.length; ++i) {
    params.slideshowIndex = i + 1;
    slideshow(containers[i], params);
  }
}
function slideshow(container, params) {
  params.enablePageViewTracking = true;
  params.trackingLabel = "slideshow";
  params.containsVideo = !!container.getElementsByClassName("video-player").length;
  const _slider = slider(container, params);
  if (!_slider) return;
  const items = container.getElementsByClassName("slider__item");
  const itemLength = items.length;
  loadLazyImages(1, 2);
  if (itemLength > 2) {
    _slider.onFirstInteraction(loadLazyImages.bind(null, 2));
  }
  bullets(container, _slider, 0);
  function loadLazyImages(start) {
    let stop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : itemLength;
    for (let i = start; i < stop; ++i) {
      const lazyImage = items[i].getElementsByClassName("lazy")[0];
      if (!lazyImage) return;
      manuallyLoadImage(lazyImage);
    }
  }
}