import { pushPageView } from "@exp/scripts/integration/gtm";
export default function trackPageUnfreeze() {
  window.addEventListener("pageshow", event => {
    if (event.persisted) {
      pushPageView();
      window.ExpressenAnalytics = window.ExpressenAnalytics || [];
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.pageView();
      });
    }
    if (window.Exp.experiments.bfcache) {
      trackBFCacheHit(event.persisted);
    }
  });
}
function trackBFCacheHit(cacheHit) {
  const navigationEntry = window.performance.getEntriesByType("navigation")[0];
  if (!cacheHit && navigationEntry.type !== "back_forward") return;
  window.ExpressenAnalytics.push(api => {
    api.track.customActive("bfcache", cacheHit ? "hit" : "miss");
  });
}