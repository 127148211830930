import scroll from "../helpers/scroll";
const supportsIntersectionObserver = ("IntersectionObserver" in window);
const loaders = [];
export { setup as default, manuallyLoadImage };
function setup(scope, imageSet, contexts) {
  scope = scope || createArtificialScope(imageSet) || document;
  const loadOffset = window.innerHeight;
  const loader = supportsIntersectionObserver ? setupIntersectionObserver(scope, loadOffset) : setupScrollListener(scope, loadOffset, contexts);
  if (loader) loaders.push(loader);
}
function manuallyLoadImage(image) {
  loadImage(image);
  for (let i = 0; i < loaders.length; ++i) {
    loaders[i].unobserve(image);
  }
}
function setupIntersectionObserver(scope, loadOffset) {
  const images = scope.getElementsByClassName("lazy");
  if (!images.length) return;
  const observer = new IntersectionObserver(viewPortUpdate, {
    rootMargin: `${loadOffset}px 0px`,
    threshold: 0.01
  });
  for (let i = 0; i < images.length; ++i) {
    observer.observe(images[i]);
  }
  return {
    unobserve(image) {
      observer.unobserve(image);
    }
  };
  function viewPortUpdate(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        loadImage(entry.target);
      }
    });
  }
}
function setupScrollListener(scope, loadOffset, contexts) {
  const columnImageElements = getImagesByContext();
  if (!columnImageElements) return;
  const viewportHeight = window.innerHeight;
  const initialScrollPosition = window.pageYOffset;
  const visitedArea = {
    start: initialScrollPosition,
    top: initialScrollPosition,
    bottom: initialScrollPosition
  };
  let columnPendingImages = columnImageElements.map(imageElements => {
    const images = [];
    for (let i = 0; i < imageElements.length; ++i) {
      const element = imageElements[i];
      const {
        top,
        bottom
      } = element.getBoundingClientRect();
      images.push({
        element,
        top,
        bottom
      });
    }
    return sortImages(images);
  });
  scroll.add(checkInView);
  return {
    unobserve(image) {
      for (let p = 0; p < columnPendingImages.length; ++p) {
        const pendingImages = columnPendingImages[p];
        for (const collectionName in pendingImages) {
          const collection = pendingImages[collectionName];
          const index = collection.findIndex(observedImage => observedImage.element === image);
          if (index === -1) continue;
          return collection.splice(index, 1);
        }
      }
    }
  };
  function reset(scrollPosition) {
    const startPositionDelta = visitedArea.start - scrollPosition;
    visitedArea.start = scrollPosition;
    visitedArea.top = scrollPosition;
    visitedArea.bottom = scrollPosition;
    columnPendingImages = columnPendingImages.map(pendingImages => {
      return pendingImages.above.concat(pendingImages.below);
    }).map(images => {
      images = images.map(image => {
        image.top += startPositionDelta;
        image.bottom += startPositionDelta;
        return image;
      });
      return sortImages(images);
    });
  }
  function getImagesByContext() {
    let count = 0;
    let filteredContexts = contexts.map(c => scope.getElementsByClassName(c)[0]).filter(Boolean);
    if (!filteredContexts.length) {
      filteredContexts = [scope];
    }
    const images = filteredContexts.map(context => {
      const imageElements = context.getElementsByClassName("lazy");
      count += imageElements.length;
      return imageElements;
    });
    return count ? images : undefined;
  }
  function sortImages(images) {
    const above = [];
    const below = [];
    for (let i = 0; i < images.length; ++i) {
      const image = images[i];
      const viewportDelta = signViewportDelta(image.top, image.bottom);
      if (!viewportDelta) {
        loadImage(image.element);
      } else if (viewportDelta > 0) {
        below.push(image);
      } else {
        above.push(image);
      }
    }
    return {
      above: above.sort((a, b) => a.top - b.top),
      below: below.sort((a, b) => b.bottom - a.bottom)
    };
  }
  function signViewportDelta(top, bottom) {
    let value = 0;
    if (bottom < -loadOffset) --value;
    if (top > loadOffset + viewportHeight) ++value;
    return (value > 0) - (value < 0) || +value;
  }
  function checkInView(_ref) {
    let {
      scrollY,
      lastScrollY
    } = _ref;
    if (alreadyVisited(scrollY)) return;
    const scrollingDown = scrollY > lastScrollY;
    const unexpectedDelta = scrollingDown ? -1 : 1;
    columnPendingImages.forEach(pendingImages => {
      const collection = scrollingDown ? pendingImages.below : pendingImages.above;
      const firstLoopIndex = collection.length - 1;
      for (let i = firstLoopIndex; i >= 0; --i) {
        const {
          element
        } = collection[i];
        const {
          top,
          bottom
        } = element.getBoundingClientRect();
        const viewportDelta = signViewportDelta(top, bottom);
        if (i === firstLoopIndex && viewportDelta === unexpectedDelta) return reset(scrollY);
        if (viewportDelta) return;
        loadImage(element);
        collection.pop();
      }
    });
  }
  function alreadyVisited(currentPosition) {
    const bottom = Math.min(currentPosition, visitedArea.bottom);
    if (bottom === currentPosition) {
      visitedArea.bottom = bottom;
      return false;
    }
    const top = Math.max(currentPosition, visitedArea.top);
    if (top === currentPosition) {
      visitedArea.top = top;
      return false;
    }
    return true;
  }
}
function loadImage(image) {
  const img = image.getElementsByTagName("img")[0];
  img.setAttribute("srcset", img.dataset.srcset);
}
function createArtificialScope(imageSet) {
  if (!imageSet) return;
  return {
    getElementsByClassName: query => {
      return query === "lazy" ? imageSet : [];
    }
  };
}