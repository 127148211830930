import { checkConsentForVendorSync } from "../integration/didomi";
import { getCurrentScheme } from "./colorScheme";
let customCss;
const requiredVendors = ["c:bn-facebook", "c:bn-instagram", "c:bn-youtube", "c:bn-twitter"];
export default function setup(scope) {
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(() => {
    const vendorsConsent = !requiredVendors.some(vendorId => !checkConsentForVendorSync(vendorId));
    const widgets = (scope || document).getElementsByClassName("live-report-widget");
    customCss = customCss || window.Exp.ifragasattCustomCss[getCurrentScheme()];
    for (let i = 0, len = widgets.length; i < len; i++) {
      liveReportWidget(widgets[i], {
        customCss,
        consent: vendorsConsent
      });
    }
  });
}
function liveReportWidget(widget, options) {
  const id = widget.dataset.resourceId;
  if (!id) return;
  fetch(`https://direkt.ifragasatt.se/load/?aId=${id}&customCss=${options.customCss}&userConsent=${!!options.consent}`).then(response => response.json()).then(data => {
    const script = document.createElement("script");
    script.appendChild(document.createTextNode(data.src));
    widget.appendChild(script);
  }).catch(() => 0);
}